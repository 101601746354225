import { fetchWrapper } from "@/helpers";

const ruta = process.env.VUE_APP_ROOT_API;
export default class ProductService {
	async getAllProductos(parametros) {
		const queryParams = parametros
			? Object.keys(parametros)
				.map(
					(k) =>
						encodeURIComponent(k) + "=" + encodeURIComponent(parametros[k])
				)
				.join("&")
			: "";
		const productos = await fetchWrapper.get(
			`${ruta}/producto?` + queryParams
		);
		return productos;
	}

	async registrarProducto(producto) {
		return await fetchWrapper.post(`${ruta}/producto`, producto);
	}

	async actualizarProducto(producto) {
		return await fetchWrapper.put(`${ruta}/producto/` + producto.id, producto);
	}
	async showProducto(producto) {
		const ProductoShow = await fetchWrapper.get(`${ruta}/producto/` + producto);
		return ProductoShow;
	}

	async deleteProducto(producto) {
		const deleteProd = await fetchWrapper.delete(
			`${ruta}/producto/` + producto.id
		);
		return deleteProd;
	}

	async buscarXNombre(datos) {
		return await fetchWrapper.post(`${ruta}/producto/buscar`, datos);
	}
	async buscarXNombreVenta(datos) {
		return await fetchWrapper.post(`${ruta}/producto/buscar_para_venta`, datos);
	}

	async buscarXCodProducto(datos) {
		return await fetchWrapper.post(`${ruta}/productocod/buscar`, datos);
	}
	async importarProductos(productos) {
		return await fetchWrapper.postFiles(`${ruta}/productos-import`, productos);
	}
	async importarPaquete(productos) {
		return await fetchWrapper.postFiles(`${ruta}/paquete-import`, productos);
	}
	async filtrarProductos(datos) {
		return await fetchWrapper.post(`${ruta}/filtros_producto`, datos);
	}
	async imprimirPDF(datos) {
		return await fetchWrapper.postPdf(
			`${ruta}/reporte/producto/imprimir`,
			datos
		);
	}
	async exportarProductos(datos) {
		const exportado = await fetchWrapper.postWithBlob(
			`${ruta}/productos-export`,
			datos
		);
		return exportado;
	}

	async getProductos_fecha_vence() {
		return await fetchWrapper.get(`${ruta}/productos_fecha_vence`);
	}

	async filtrarFechaVence(datos) {
		return await fetchWrapper.post(`${ruta}/filtros_fecha_vence`, datos);
	}

	async printRFechaVence(datos) {
		return await fetchWrapper.postPdf(`${ruta}/reporte/producto_fecha_vence/imprimir`, datos);
	}

	async exportFechaVence(datos) {
		const exportado = await fetchWrapper.postWithBlob(`${ruta}/productos_fecha_vence_export`, datos);
		return exportado;
	}

	async getProductos_stock_minimo() {
		return await fetchWrapper.get(`${ruta}/productos_stock_minimo`);
	}

	async buscar_x_codigo_nombre(datos) {
		return await fetchWrapper.post(`${ruta}/producto/buscar_x_codigo_nombre`, { codigo: datos });
	}
	async getProductoImages(producto_id) {
		return await fetchWrapper.post(`${ruta}/producto/${producto_id}/images`);
	}
	async uploadImage(datos) {
		return await fetchWrapper.postFiles(`${ruta}/producto/images/upload`, datos);
	}

	async deleteImage(imagen_id) {
		return await fetchWrapper.delete(`${ruta}/producto/images/${imagen_id}`);
	}
	async sincronizarSIN(){
		return await fetchWrapper.post(`${ruta}/sincronizarSIN`);
	}
	async getCodigoSIN(){
		return await fetchWrapper.get(`${ruta}/productos-codigo-sin`);
	}

	async getDatosNecesariosProducto(){
		return await fetchWrapper.post(`${ruta}/producto/datos-necesarios`);
	}
}
