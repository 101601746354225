import { fetchWrapper } from "@/helpers";

const ruta = process.env.VUE_APP_ROOT_API;

export default class LaboratorioService {
  async sendNuevoLaboratorio(Laboratorio) {
    const nuevoLaboratorio = await fetchWrapper.post(
      `${ruta}/fabrica`,
      Laboratorio
    );
    return nuevoLaboratorio;
  }

  async getAllLaboratorios() {
    return await fetchWrapper.get(`${ruta}/fabrica`);
  }

  async updatedLab(laboratorio) {
    const labActualizado = await fetchWrapper.put(
      `${ruta}/fabrica/` + laboratorio.id,
      laboratorio
    );
    return labActualizado;
  }

  async deleteLab(laboratorio) {
    const deleteLab = await fetchWrapper.delete(
      `${ruta}/fabrica/` + laboratorio.id
    );
    return deleteLab;
  }
  async showLab(laboratorio) {
    const labShow = await fetchWrapper.get(`${ruta}/fabrica/` + laboratorio);
    return labShow;
  }
}
